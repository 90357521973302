@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+Expanded:wght@400;500;700;900&display=swap');


:root {
  --white: #ffffff;
  --white-2: #fbfbfb;
  --white-3: #f8fafc;
  --black: #010606;
  --black-2: #0d0d0d;
  --gray: #2f495e;
  --gray-2: #606f7b;
  --primary: #83c549;
  --light-primary: #c5f899;
  --border-color: #ececec;
  --navbar-height: 80px;
  --section-padding: 70px;
  --font-size: 16px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  min-width: 320px;
  margin: 0;
  font-size: var(--font-size);
  line-height: 1.6;
  /* font-family: Quicksand, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; */
  font-family: 'Encode Sans Expanded', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

section {
  padding: var(--section-padding) 0;
}

.container {
  max-width: 1100px;
  padding: 0 24px;
  margin: 0 auto;
}

.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: 700ms;
  animation-fill-mode: both;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.section-shadow {
  box-shadow: 0 0 8px rgba(0,0,0,.101562);
  z-index: 1;
}

.shadow {
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06);
}